exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academicadvantage-js": () => import("./../../../src/pages/academicadvantage.js" /* webpackChunkName: "component---src-pages-academicadvantage-js" */),
  "component---src-pages-es-resultados-de-la-busqueda-js": () => import("./../../../src/pages/es/resultados-de-la-busqueda.js" /* webpackChunkName: "component---src-pages-es-resultados-de-la-busqueda-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-wisdom-application-js": () => import("./../../../src/pages/wisdom-application.js" /* webpackChunkName: "component---src-pages-wisdom-application-js" */),
  "component---src-pages-wisdom-js": () => import("./../../../src/pages/wisdom.js" /* webpackChunkName: "component---src-pages-wisdom-js" */),
  "component---src-pages-wisdom-step-1-js": () => import("./../../../src/pages/wisdom-step-1.js" /* webpackChunkName: "component---src-pages-wisdom-step-1-js" */),
  "component---src-pages-wisdom-step-2-js": () => import("./../../../src/pages/wisdom-step-2.js" /* webpackChunkName: "component---src-pages-wisdom-step-2-js" */),
  "component---src-pages-wisdom-terms-conditions-js": () => import("./../../../src/pages/wisdom-terms-conditions.js" /* webpackChunkName: "component---src-pages-wisdom-terms-conditions-js" */),
  "component---src-templates-anesthesia-options-js": () => import("./../../../src/templates/anesthesia-options.js" /* webpackChunkName: "component---src-templates-anesthesia-options-js" */),
  "component---src-templates-community-js": () => import("./../../../src/templates/community.js" /* webpackChunkName: "component---src-templates-community-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-exparel-asird-js": () => import("./../../../src/templates/exparel-asird.js" /* webpackChunkName: "component---src-templates-exparel-asird-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-make-a-payment-js": () => import("./../../../src/templates/make-a-payment.js" /* webpackChunkName: "component---src-templates-make-a-payment-js" */),
  "component---src-templates-open-for-care-js": () => import("./../../../src/templates/open-for-care.js" /* webpackChunkName: "component---src-templates-open-for-care-js" */),
  "component---src-templates-our-oral-surgery-practice-js": () => import("./../../../src/templates/our-oral-surgery-practice.js" /* webpackChunkName: "component---src-templates-our-oral-surgery-practice-js" */),
  "component---src-templates-patient-instructions-js": () => import("./../../../src/templates/patient-instructions.js" /* webpackChunkName: "component---src-templates-patient-instructions-js" */),
  "component---src-templates-patient-stories-js": () => import("./../../../src/templates/patient-stories.js" /* webpackChunkName: "component---src-templates-patient-stories-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-procedures-js": () => import("./../../../src/templates/procedures.js" /* webpackChunkName: "component---src-templates-procedures-js" */),
  "component---src-templates-single-community-js": () => import("./../../../src/templates/singleCommunity.js" /* webpackChunkName: "component---src-templates-single-community-js" */),
  "component---src-templates-single-doctor-js": () => import("./../../../src/templates/singleDoctor.js" /* webpackChunkName: "component---src-templates-single-doctor-js" */),
  "component---src-templates-single-instruction-js": () => import("./../../../src/templates/singleInstruction.js" /* webpackChunkName: "component---src-templates-single-instruction-js" */),
  "component---src-templates-single-language-procedure-js": () => import("./../../../src/templates/single-language-procedure.js" /* webpackChunkName: "component---src-templates-single-language-procedure-js" */),
  "component---src-templates-single-location-js": () => import("./../../../src/templates/singleLocation.js" /* webpackChunkName: "component---src-templates-single-location-js" */),
  "component---src-templates-single-procedure-animated-js": () => import("./../../../src/templates/singleProcedureAnimated.js" /* webpackChunkName: "component---src-templates-single-procedure-animated-js" */),
  "component---src-templates-single-procedure-js": () => import("./../../../src/templates/singleProcedure.js" /* webpackChunkName: "component---src-templates-single-procedure-js" */),
  "component---src-templates-single-review-js": () => import("./../../../src/templates/singleReview.js" /* webpackChunkName: "component---src-templates-single-review-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-three-d-imaging-js": () => import("./../../../src/templates/three-d-imaging.js" /* webpackChunkName: "component---src-templates-three-d-imaging-js" */),
  "component---src-templates-your-first-visit-js": () => import("./../../../src/templates/your-first-visit.js" /* webpackChunkName: "component---src-templates-your-first-visit-js" */)
}

